import '../../stylesheets/customer_information_group/customer_information_group_smart_phone.css'
import '../../stylesheets/customer_information_group/information_attachments.scss'
import "../../stylesheets/customer_information_group/default_style.scss"

/**
 * ヘッダーアイコンに拡大画像を閉じるイベントを付与
*/
function addNavClickListeners() {
  const navItems = document.getElementsByClassName("nav-item");
  Array.from(navItems).forEach(item => {
    item.addEventListener("click", redisplayHiddenContent);
  });
}

/**
 * 画像のサイズを設定
*/
function adjustImageAreaSize(img, imageArea) {
  img.addEventListener("load", () => {
    const resizeRate = (window.innerWidth * 0.95) / img.naturalWidth;
    const maxHeight = (screen.height - imageArea.getBoundingClientRect().top) - 150;
    imageArea.style.height = `${Math.min((img.naturalHeight * resizeRate), maxHeight)}px`;
  });
}

/**
 * 画像を閉じてお知らせ本文を再表示する
*/
window.closeImageArea = function() {
  const prevScrollY = getPreviousScrollY();
  hideEnlargedImageArea();
  showDetailArea();
  redisplayHiddenContent();
  scrollToPreviousPosition(prevScrollY);
}

/**
 * 真偽値に変換する
 * 文字列以外の場合はそのまま判定、
 * 文字列の場合はJSON.parse()を用いて判定する
 * @param {} str 変換したい値
 * @return {Boolean} 判定結果
*/
function convertStrToBool(str){
  if (typeof str != "string")
    return Boolean(str);

  try {
    const obj = JSON.parse(str.toLowerCase());
    return obj == true;
  } catch (e) {
    return str != "";
  }
}

/**
 * ＞アイコンの描画
 * @return {Element}
*/
function createArrowArea() {
  const rightArrow = document.createElement("div");
  rightArrow.classList.add("d-table", "ms-2");
  rightArrow.innerHTML = "<span class='d-table-cell align-middle icon-right02'></span>";

  return rightArrow;
}

/**
 * Newアイコン(赤丸)の描画
 * @param {Boolean} 未読フラグ
 * @return {Element}
*/
function createIconArea(unreadFlg) {
  const iconArea = document.createElement("div");
  iconArea.classList.add("p-3", "position-relative");
  if (unreadFlg)
    iconArea.innerHTML = "<span class=\"position-absolute translate-middle p-2 bg-danger rounded-circle\"></span>";

  return iconArea;
}

/**
 * 表示画像を作成
 * @param {Element} elem 呼出元の画像
 * @return {Element} 表示するimgタグ
*/
function createImageElement(elem) {
  const img = document.createElement("img");
  img.alt = elem.alt;
  img.style.width = "100%";
  img.style.transform = "translate(0, 0)";
  img.src = elem.src;
  return img;
}

/**
 * お知らせタイトルの描画
 * @param {String} date 配信日(○○○○年○○月○○日)
 * @param {String} title お知らせタイトル
 * @param {String} body お知らせ本文
 * @param {String} importantFlg 重要フラグ
 * @param {Boolean} unreadFlg 未読フラグ
 * @return {Element}
*/
function createInfoArea(date, title, body, importantFlg, unreadFlg) {
  let klass = unreadFlg ? "fw-bold" : "text-muted";
  const detailArea = document.createElement("div");
  detailArea.classList.add("flex-fill", "me-2", "w-50");
  let elem = `
    <div class="d-flex justify-content-between fs-5 ${klass}">${date}</div>
    <div class="fs-3 ${klass}">${title}</div>
    <div class="fs-3 ${klass} text-truncate">${body}</div>
  `
  detailArea.innerHTML = elem;
  if (convertStrToBool(importantFlg)) {
    const importantBadth = document.createElement("span");
    importantBadth.classList.add("badge", "bg-danger");
    importantBadth.innerHTML = "重要";
    detailArea.children[0].appendChild(importantBadth);
  };

  return detailArea;
}

/**
 * お知らせの描画エリア作成
 * @param {String} id お知らせのID
 * @param {Boolean} unreadFlg 未読フラグ
 * @return {Element}
*/
function createInfoContainer(id, unreadFlg) {
  const infoContainer = document.createElement("div");
  const boxId = `status_information_${id}`;
  infoContainer.setAttribute("id", boxId);
  infoContainer.classList.add("border-bottom", "border-dark", "d-flex", "mx-3", "my-2", "pb-1");
  infoContainer.setAttribute("onclick", "showInformation(this);");

  return infoContainer;
}

/**
 * バッジ表示用の未読数
 * @param {Integer} cnt 未読数
 * @return {String} 表示用に修正後の未読数
*/
function displayCnt(cnt) {
  let resCnt = cnt;
  if(resCnt >= 100)
    resCnt = "99+";

  return resCnt;
}

/**
 * 画像を表示エリアに設定する
 * @param {Element} img 設定する画像(imgタグ)
 * @param {Element} imageArea 表示エリア
*/
function displayImage(img, imageArea) {
  imageArea.innerHTML = "";
  imageArea.appendChild(img);
}

/**
 * 画像を拡大表示する
*/
window.expansion = function(elem) {
  setPreviousScrollY();
  scrollToPreviousPosition(0);
  const imageArea = document.getElementById("info_image_area");
  const viewElement = createImageElement(elem);
  adjustImageAreaSize(viewElement, imageArea);
  displayImage(viewElement, imageArea);
  hideDetailArea();
  showEnlargedImageArea();
  hiddenOtherContent();
  addImageFunction();
}

/**
 * 現在のスクロール位置を取得
 * @return {Integer} 画面のY座標
*/
function getPreviousScrollY() {
  return document.getElementById("prev_scroll_y").value;
}

/**
 * お知らせ詳細を非表示にする
*/
function hideDetailArea() {
  document.getElementById("info_detail_area").classList.add("d-none");
}

/**
 * 拡大画像の表示エリアを非表示にする
*/
function hideEnlargedImageArea() {
  document.getElementById("enlarged_image").classList.add("d-none");
}

/**
 *ローディング画像非表示
*/
function loading_end(){
  $('.dialog_loding').css('display', 'none');
}

/**
 * ローディング画像表示
*/
function loading_start(){
  $('.dialog_loding').css('display', 'flex');
}

/**
 * もっと見るボタン押下時の処理
*/
window.moreView = function() {
  document.getElementById("current_cnt");
  const hiddenInfos = document.getElementById("info_list_container").getElementsByClassName("hidden");

  for (let i = 0; i < 10; i++) {
    if (hiddenInfos.length <= 0)
      break;

    hiddenInfos[0].classList.remove("hidden", "d-none");
  }

  if (hiddenInfos.length <= 0)
    document.getElementById("more_push_info").classList.add("d-none");
}

/**
 * 画像操作の関数を付与する
*/
window.addImageFunction = function() {
  const EX_RATE = 5;                                         // 画像を拡大する割合の上限値
  const target = document.getElementById("info_image_area"); // 画像表示エリア
  const maxWidth = target.clientWidth * EX_RATE;             // 画像の幅の最大値
  const minWidth = target.clientWidth;                       // 画像の幅の最小値
  let beforeWidth = 0;                                       // 拡大/縮小操作前の画像の幅
  let startCoordX = 0;                                       // 移動操作開始時点の指のX座標
  let startCoordY = 0;                                       // 移動操作開始時点の指のY座標
  let startDistance = 0;                                     // 拡大/縮小操作開始時点の２点間の距離
  let startTranslateX = 0;                                   // 移動操作開始時点の画像のX座標
  let startTranslateY = 0;                                   // 移動操作開始時点の画像のY座標

  target.addEventListener("touchstart", handleTouchStart);
  target.addEventListener("touchmove", handleTouchMove);

  /**
   * 操作開始時点の情報を取得
  */
  function handleTouchStart(e) {
    const image = target.children.item(0);
    if (e.touches.length > 1) {
      beforeWidth = image.clientWidth;
      startDistance = getDistanceBetweenPoints(e);
    } else if (e.touches.length === 1) {
      startCoordX = e.touches[0].pageX;
      startCoordY = e.touches[0].pageY;

      // 操作開始前の画像の位置を取得
      const imageTransform = window.getComputedStyle(image).getPropertyValue("transform");
      const imageMatrix = imageTransform.match(/^matrix\((.+)\)$/)[1].split(", ");
      startTranslateX = Number(imageMatrix[4]);
      startTranslateY = Number(imageMatrix[5]);
    }
  }

  /**
   * 画像を拡大/縮小、移動させる
  */
  function handleTouchMove(e) {
    e.preventDefault();

    const image = target.children.item(0);
    if (e.touches.length > 1)
      handlePinchZoom(e, image);
    else if (e.touches.length === 1)
      handleDrag(e, image);
  }

  /**
   * 画像のピンチイン/アウト操作
  */
  function handlePinchZoom(e, image) {
    const currentDistance = getDistanceBetweenPoints(e);
    const movedDistance = currentDistance - startDistance;
    // 拡大/縮小後の画像の幅
    let afterWidth = beforeWidth + getFluctuationValue(movedDistance);

    // 画像の幅が最大/最小値を超えないように調整する
    afterWidth = Math.min(Math.max(afterWidth, minWidth), maxWidth)

    // 画像を拡大/縮小させる
    image.style.width = `${afterWidth}px`;
  }

  /**
   * 画像のドラッグ操作
  */
  function handleDrag(e, image) {
    // 画像が移動できる上限値を設定
    const maxDistanceX = target.clientWidth - image.clientWidth;
    const maxDistanceY = target.clientHeight - image.clientHeight;
    // 画像の移動距離を取得
    let moveCoordX = startTranslateX + (e.touches[0].pageX - startCoordX);
    let moveCoordY = startTranslateY + (e.touches[0].pageY - startCoordY);

    // 画像が表示エリアから出ないように調整
    moveCoordX = Math.min(Math.max(moveCoordX, maxDistanceX), 0);
    moveCoordY = Math.min(Math.max(moveCoordY, maxDistanceY), 0);

    // 画像を移動させる
    image.style.transform = `translate(${moveCoordX}px, ${moveCoordY}px)`;
  }

  /**
   * ２点間の距離を算出する
  */
  function getDistanceBetweenPoints(e) {
    // 水平方向の距離
    const pointX = e.touches[1].pageX - e.touches[0].pageX;
    // 垂直方向の距離
    const pointY = e.touches[1].pageY - e.touches[0].pageY;

    // ピタゴラスの定理から２点を結ぶ直線の距離を算出する
    const distanceSquared = Math.pow(pointX, 2) + Math.pow(pointY, 2);
    return Math.sqrt(distanceSquared);
  }

  /**
   * 画像を拡大/縮小させる割合を算出
   *
   * 移動した距離を長辺とする直角二等辺三角形の短辺の長さだけ、画像を拡大/縮小させる
  */
  function getFluctuationValue(distance) {
    const squared = Math.pow(distance, 2) / 2;
    return distance >= 0 ? Math.sqrt(squared) : -Math.sqrt(squared);
  }
}

/**
 * お知らせを開いたときに
 * 一覧と詳細をリセットする
*/
window.openDeliveryStatus = function() {
  const targetId = window.event.currentTarget.id;
  $.ajax({
    data_type: "json",
    headers: { "X-CSRF-Token" : $("meta[name='csrf-token']").attr("content") },
    type: "POST",
    url: "/customer_information_group/return_info_list"
  }).done(function (result) {
    const info = result["customer_information_groups"];
    const unreadCnt = result["unread_cnt"];

    $("#header_status_icon").find(".unread_count").text(displayCnt(unreadCnt));
    resetInfoList(info);
    resetInfoDetails();

    const more_btn = document.getElementById("more_push_info")
    if (info.length > 10)
      more_btn.classList.remove("hidden", "d-none");
    else
      more_btn.classList.add("hidden", "d-none");

    if (targetId == "info_title") {
      const infoPos = document.getElementById("info_list_container").getBoundingClientRect();
      window.scroll({top: infoPos.top, behavior: 'auto'});
    }
  });

  document.getElementById("enlarged_image").classList.add("d-none");
  document.getElementById("info_detail_area").classList.add("d-none");
  document.getElementById("delivery_status_area").classList.remove("d-none");
  window.scroll({top: 0, behavior: 'instant'});
}

/**
 * ふきだしタップでお知らせ一覧を開く
*/
window.openStatus = function() {
  $('#header_status_icon').trigger("click");
};

/**
 * 画像より下のコンテンツを非表示にする
*/
function hiddenOtherContent() {
  const elementsToHide = [
    document.getElementById("header_content"),
    document.getElementById("body_content"),
    document.getElementById("footer_content"),
    document.getElementsByClassName("attention_info_content").item(0),
    document.getElementsByClassName("body-content").item(0)
  ];

  elementsToHide.forEach(element => {
    if (element) {
      element.classList.add("d-none");
    }
  });

  addNavClickListeners();
}

/**
 * 非表示にしたコンテンツを元に戻す
*/
function redisplayHiddenContent() {
  const elementsToShow = [
    document.getElementById("header_content"),
    document.getElementById("body_content"),
    document.getElementById("footer_content"),
    document.getElementsByClassName("attention_info_content").item(0),
    document.getElementsByClassName("body-content").item(0)
  ];

  elementsToShow.forEach(element => {
    if (element) {
      element.classList.remove("d-none");
    }
  });

  removeNavClickListeners();
}

/**
 * ヘッダーアイコンから拡大画像を閉じるイベントを外す
*/
function removeNavClickListeners() {
  const navItems = document.getElementsByClassName("nav-item");
  Array.from(navItems).forEach(item => {
    item.removeEventListener("click", redisplayHiddenContent);
  });
}

/**
 * お知らせ詳細の再描画
*/
function resetInfoDetails() {
  const detailArea = document.getElementById("info_detail_area");
  const btn = detailArea.getElementsByClassName("dsn-btn").item(0);

  detailArea.textContent = '';
  detailArea.insertAdjacentElement('afterbegin', btn);
}

/**
 * お知らせ一覧の再描画
 * @param {JSON} infos 公開中のお知らせの一覧
*/
function resetInfoList(infos) {
  const infoListContainer = document.getElementById("info_list_container");
  infoListContainer.textContent = "";
  let roopCnt = 0;
  for (const info of infos) {
    const unreadFlg = !convertStrToBool(info["confirm_state"]);
    const infoBox = createInfoContainer(info["id"], unreadFlg);
    if (roopCnt > 9)
      infoBox.classList.add("hidden", "d-none");

    infoBox.appendChild(createIconArea(unreadFlg));

    const date = new Date(info["start_at"]);
    const dateText = `${date.getFullYear()}年${("  " + (date.getMonth() + 1)).slice(-2)}月${("  " + date.getDate()).slice(-2)}日`;
    infoBox.appendChild(createInfoArea(dateText, info["title"], info["body"], info["important_flg"], unreadFlg));

    infoBox.appendChild(createArrowArea());
    infoListContainer.appendChild(infoBox);

    roopCnt++;
  }
}

/**
 * 画面をスクロールさせる
 * @param {Integer} scrollY スクロール位置
*/
function scrollToPreviousPosition(scrollY) {
  window.scroll({ top: scrollY, behavior: 'instant' });
}

/**
 * 現在の画面のスクロール位置を設定する
*/
function setPreviousScrollY() {
  document.getElementById("prev_scroll_y").value = window.scrollY;
}

/**
 * お知らせ詳細を表示する
*/
function showDetailArea() {
  document.getElementById("info_detail_area").classList.remove("d-none");
}

/**
 * 拡大画像を表示する
*/
function showEnlargedImageArea() {
  document.getElementById("enlarged_image").classList.remove("d-none");
}

/**
 * お知らせ詳細の表示
 * @param {Element} elem 選択されたお知らせの要素
*/
window.showInformation = function(elem) {
  loading_start();

  const infoId = elem.id.substring(19); // status_information_ の後ろのIDを取得
  $.ajax({
    data: { id: infoId },
    data_type: "html",
    headers: { "X-CSRF-Token" : $("meta[name='csrf-token']").attr("content") },
    type: "POST",
    url: "/customer_information_group/get_information"
  }).done(function(result){
    if(result["err"] === undefined){
      changeUnreadCnt();
      document.getElementById("info_detail_area").insertAdjacentHTML('afterbegin', result);
    }else{
      const body = document.createElement("div");
      body.classList.add('fs-2');
      body.setAttribute('id', 'info_detail_body');
      body.textContent = result["err"];

      document.getElementById("info_detail_area").insertAdjacentElement('afterbegin', body);
    };
    document.getElementById("delivery_status_area").classList.add("d-none");
    document.getElementById("info_detail_area").classList.remove("d-none");

    window.scroll({top: 0, behavior: 'auto'});
  }).always(function(){
    loading_end();
  });
}

/**
 * 未読件数貼替
*/
function changeUnreadCnt(){
  $.ajax({
    data_type: "json",
    headers: { "X-CSRF-Token" : $("meta[name='csrf-token']").attr("content") },
    type: "POST",
    url: "/customer_information_group/return_info_list"
  }).done(function (result) {
    const unreadCnt = result["unread_cnt"];
    $('#header_status_icon').find(".unread_count").text(displayCnt(unreadCnt));

    if (unreadCnt == "0"){
      $('#header_status_icon').find(".unread_badge").remove();
    };
  });
}

/**
* PDF読み取り
*/
window.readPDF = async(elem) => {
  var id = elem.dataset.id;
  const csrfToken = document.getElementsByName("csrf-token")[0].content;
  const url = "/customer_information_group/" + id + "/pdf_read";

  const result = await fetch(url, {
    method: "get",
    headers: {
      "Content-Type": "application/json",
      "X-CSRF-Token": csrfToken
    }
  });
  
  var terminal = result["url"].substr(-2);
  
  if (result["status"] == 200){
    downloadPDF(id);
  }else if(terminal == 'sp'){
    jAlert("ファイルが取得できませんでした。", "");
  }else if(location.pathname.substr(1,5) == 'admin'){
    alert('ファイルが取得できませんでした。');
  }
  else{ 
    tb_show("","#TB_inline?height=255&width=500&inlineId=pdf_not_found","");
    return false;  
  };
}

/**
* PDFダウンロード
*/
window.downloadPDF = async(id) => {
  const csrfToken = document.getElementsByName("csrf-token")[0].content;
  const url = "/customer_information_group/" + id + "/pdf_download";
  const result = await fetch(url, {
    method: "get",
    headers: {
      "Content-Type": "application/json",
      "X-CSRF-Token": csrfToken
    }
  });

  location.href = result["url"];
}
